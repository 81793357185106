import { graphql, useStaticQuery } from "gatsby";

export type Mostra = {
  nome: string;
  data: string;
  luogo?: string;
  foto: string[];
  societa?: string;
  riconoscimenti?: string;
  commenti?: string;
};
type Props = {
  allDataJson: {
    nodes: {
      mostre: Mostra[];
    }[];
  };
};

const useMostreMetadata = () => {
  const data = useStaticQuery<Props>(graphql`
    query FetchAllMostre {
      allDataJson {
        nodes {
          mostre {
            commenti
            foto
            luogo
            nome
            riconoscimenti
            societa
          }
        }
      }
    }
  `);
  console.log(data);
  return data.allDataJson.nodes.filter((node) => node.mostre !== null)[0];
};

export default useMostreMetadata;
