import * as React from "react";
import Layout from "@lekoarts/gatsby-theme-jodie/src/components/layout";
import { Heading } from "theme-ui";
import { Timeline, TextEvent, Events, ImageEvent } from "@merc/react-timeline";
import { graphql } from "gatsby";
import useMostreMetadata from "../@lekoarts/gatsby-theme-jodie/hooks/use-mostre-json";
import { Mostra } from "../@lekoarts/gatsby-theme-jodie/hooks/use-mostre-json";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Card, Row, Col, Image } from "react-bootstrap";
import { Modal } from "react-bootstrap";
//import { FaMedal } from "react-icons/fa";
interface ModalCardImage {
  show: boolean;
  onClose: () => void;
  selectedImage: string;
}

const ModalCardImage: React.FC<ModalCardImage> = ({
  show,
  onClose,
  selectedImage,
}) => {
  return (
    <Modal show={show} onHide={onClose} centered={true}>
      <Modal.Body>
        <Row className="justify-content-md-center">
          <Col>
            <Image fluid src={selectedImage} />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Chiudi
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

interface CardMostraInterface {
  titolo: string;
  luogo: string;
  societa: string;
  commenti: string;
  riconoscimenti: string;
  data: string;
  foto: string[];
}

const CardMostra: React.FC<CardMostraInterface> = ({
  titolo,
  luogo,
  societa,
  commenti,
  riconoscimenti,
  data,
  foto,
}) => {
  const [showImage, setShowImage] = React.useState<boolean>(false);
  const [showModalImage, setShowModalImage] = React.useState<boolean>(false);
  const [selectedImage, setSelectedImage] = React.useState<string>();
  return (
    <Card className="text-center m-3 border-zinc-400 border-double">
      <Card.Header>
        {luogo} - {societa}
      </Card.Header>
      <Card.Body>
        <Card.Title>{titolo.split("-")[0]}</Card.Title>
        <Card.Text>{commenti}</Card.Text>
        {riconoscimenti && (
          <Card.Text>
            {/* <FaMedal color="gold" className="" /> */}
            {riconoscimenti}
            {/* <FaMedal color="gold" className="" /> */}
          </Card.Text>
        )}

        <Row className="justify-content-md-center">
          {foto.map((src, index) => (
            <Col xs={12} sm={6} md={2}>
              <Image
                fluid
                key={index}
                src={src}
                style={{ display: showImage ? "block" : "none" }}
                width="200"
                height="200"
                onClick={() => {
                  setShowModalImage(true);
                  setSelectedImage(src);
                }}
              />
            </Col>
          ))}
        </Row>

        <ModalCardImage
          show={showModalImage}
          onClose={() => setShowModalImage(false)}
          selectedImage={selectedImage}
        />

        {foto.length !== 0 && (
          <button
            onClick={() => setShowImage(!showImage)}
            className="px-4 py-2 mt-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm hover:-translate-y-1 hover:scale-110 hover:bg-green-800 ease-in-out delay-150 duration-300"
          >
            {showImage ? "Nascondi immagini" : "Mostra immagini"}
          </button>
        )}
      </Card.Body>
      <Card.Footer className="text-muted">{titolo.split("-")[1]}</Card.Footer>
    </Card>
  );
};

interface MostreComponentInterface {}

const Mostre: React.FC<MostreComponentInterface> = (props) => {
  const { mostre } = useMostreMetadata();
  const [mostreData, setMostreData] = React.useState<Mostra[]>([]);
  var options = {
    month: "long",
    day: "2-digit",
    year: "numeric",
  };

  React.useEffect(() => {
    console.log("Mostre:", mostre);

    setMostreData(mostre);
  }, [mostre.length]);

  return (
    <Layout>
      <Heading as="h1" variant="styles.h1" sx={{ mt: 0 }}>
        Le mie partecipazioni alle mostre
      </Heading>

      {console.log(
        mostreData.sort((m1, m2) => Date.parse(m1.data) - Date.parse(m2.data))
      )}
      {mostreData
        .sort(
          (m1: Mostra, m2: Mostra) =>
            new Date(m1.data).getDate() - new Date(m2.data).getDate()
        )
        .map((m, index) => (
          <CardMostra
            key={index}
            commenti={m.commenti}
            data={new Date(m.data).toLocaleString("it-IT", options as any)}
            luogo={m.luogo}
            riconoscimenti={m.riconoscimenti}
            societa={m.societa}
            titolo={m.nome}
            foto={m.foto}
          />
        ))}
    </Layout>
  );
};

export default Mostre;
